import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import ServiceHero from "../components/service-hero";
import PhotoSide from "../components/photo-side";
import Certifications from "../components/certifications";
import SmallDotsCta from "../components/small-dots-cta";
import Button from "react-bootstrap/Button";
import Link from "../components/link";

const About = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: file(relativePath: { eq: "wiring-bg.jpg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
				founderImg: file(relativePath: { eq: "roger-2.png" }) {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const heroImg = data.heroImg.childImageSharp.gatsbyImageData;
	const schemaImg = data.heroImg.publicURL;
	const founderImg = data.founderImg.childImageSharp.gatsbyImageData;
	const siteUrl = data.site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "About Us",
				item:{
					url: `${siteUrl}/about`,
					id: `${siteUrl}/about`,
			},
		}
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Who we are at Prime Sparks"
				description="Your local electrician"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Who we are at Prime Sparks",
					description: "The Prime Sparks Story of who we are",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Prime Sparks Electrical Services",
						},
					],
				}}
			/>
			<ServiceHero
				heading="About Prime Sparks"
				text={
					<span>
						Experienced, highly-qualified and expert electrician <br /> based in
						Eastbourne.
					</span>
				}
				isDarken={true}
				image={heroImg}
			/>
			<section id="rogerprime">
				<PhotoSide
					sHeading="The Founder"
					showCta="d-none"
					img={founderImg}
					height="40rem"
					heading="Roger Prime"
					text="Roger is an experienced, highly-qualified and expert electrician who is passionate about his job. After  completing an apprenticeship for a local family-run electrical company in Sussex, he was one of the  few apprentices who showed enough promise and skill to be asked to stay on as a full-time  employee. Keen to improve his skill set and further his career, Roger accepted the offer and ended up staying on as a full-time employee for 15 years."
				/>
			</section>
			<section>
				<Container>
					<Row className="justify-content-center">
						<Col xs={12} lg={8} className="text-center">
							<p className="michroma-regular quote">
								<span className="text-primary">"</span>We use Roger @ Prime
								Sparks for many of our managed properties, he is always prompt,
								efficient and never lets us down!
								<span className="text-primary">"</span>
							</p>
							<p className="quote-name">Mason Bryant</p>
						</Col>{" "}
					</Row>
				</Container>
			</section>

			<SmallDotsCta
				showCta="d-none"
				sHeading="How it all began"
				heading="Going Solo"
				text={
					<span>
						<p>
							After 15 years, Roger decided it was time to take a break and see
							a bit of the world, so he took some well-deserved time off to go
							travelling. When he came back he was ready to go solo, so Roger
							set up his own company, Prime Sparks, and he hasn’t looked back
							since.
						</p>
						<p>
							During his career Roger has gained valuable experience of working
							on a variety of domestic commercial and small industrial
							installations. All of this experience has enabled him to
							understand  the true value of customer care and attention to
							detail which he now applies to Prime Sparks.
						</p>
						<p>
							Even though regulations are constantly changing, Roger ensures
							that his professional standards and  qualifications are always up
							to date, and he is passionate about the quality of his work and
							the service he provides. Roger understands that customer
							relationships are very important as well as value for money and
							offering a reliable and efficient service.
						</p>
						<p>
							By applying his finely-tuned electrical knowledge and exemplary
							customer service skills Roger has built a successful business and
							a loyal customer base. This is clear from how many former
							customers recommend Prime Sparks to their friends and family and
							businesses looking for electrical work to be completed by a
							trusted electrician. See what Roger’s customers say about him on
							the{" "}
							<Link to="/testimonials" className="text-primary">
								Testimonials
							</Link>{" "}
							page .
						</p>
					</span>
				}
			/>
			<Certifications />
			<Container>
				<Row>
					<Col className="text-center">
						<Button
							as={Link}
							to="/contact-us"
							className="btn text-white cta-btn mt-5 mb-7"
						>
							Speak to Roger
						</Button>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default About;
